import { createGlobalStyle } from 'styled-components';
import bgImage from '../images/bg.png';
import fonts from './fonts';
import typography from './typography';

export default createGlobalStyle`
  ${fonts}
  body,html {
   height: 100%;
  }

  body {
    margin: 0;
    /* background: url(${bgImage}), black; */
    /* background-color: wheat; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  ${typography}
`;
