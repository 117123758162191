import React from 'react';
import { AlignCenter } from '../common/styled/Page';
import PropTypes from 'prop-types';
import { getCountry } from '../lib/site';
import CheckMarkAnimated from './CheckMarkAnimated';
import i18n from './i18n';
import styled from 'styled-components';
import { mora, white } from '../common/styled/colors';

const CmoreLinkButton = styled.a`
  background: ${mora};
  color: ${white};
  display: inline-block;
  border-radius: 100px;
  width: 152px;
  padding: 12px 0 12px 0;
  font-size: 16px;
  text-decoration: none;
  font-weight: 400;
  margin-bottom: 16px;
`;

const Done = ({ userHasActiveOrders, error }) => {
  return (
    <AlignCenter>
      {!error && userHasActiveOrders && (
        <React.Fragment>
          <h1> {i18n('header')} </h1>
          <p> {i18n('text')} </p>
          <div style={{ minHeight: 300 }} data-test="checkmark">
            <CheckMarkAnimated width="135px" height="135px" />
          </div>
        </React.Fragment>
      )}
      {!error && !userHasActiveOrders && (
        <React.Fragment>
          <h1> {i18n('getSubscription')} </h1>
          <p style={{ fontSize: 28, maxWidth: 580, margin: '32px auto 32px auto', lineHeight: 1.4, fontWeight: 400 }}>
            {' '}
            {i18n('textNoSubscription')}{' '}
          </p>
          <AlignCenter>
            <CmoreLinkButton href={`https://www.cmore.${getCountry()}/alla-paket`}>
              {i18n('choosePackage')}
            </CmoreLinkButton>
          </AlignCenter>
        </React.Fragment>
      )}
    </AlignCenter>
  );
};

export default Done;

Done.propTypes = {
  error: PropTypes.string,
};
