import cookie from 'js-cookie';
import { getCookieDomain } from './site';

export const COOKIE_KEYS = {
  LOGGED_IN: 'isLoggedIn',
  SESSION_TOKEN: 'sessionToken',
  ACTIVATION_CODE: 'ACTIVATION_CODE',
  ACTIVATION_SESSION_TOKEN: 'ACTIVATION_SESSION_TOKEN',
};

const parse = token => {
  return token ? JSON.parse(window.atob(token.split('.')[1])) : {};
};

const getJWTExpireTime = jwt => {
  let diff = 0;
  try {
    const JWTData = parse(jwt);
    diff = JWTData.exp - JWTData.iat;
  } catch (e) {}
  return Math.max(diff, 60 * 60) * 1000;
};

export const setCmoreCredentials = token => {
  if (!token) {
    return;
  }
  const diff = getJWTExpireTime(token);
  const sessionExpires = new Date(Date.now() + diff);

  cookie.set(COOKIE_KEYS.LOGGED_IN, 'true', { expires: sessionExpires, path: '/', domain: getCookieDomain() });
  cookie.set(COOKIE_KEYS.SESSION_TOKEN, token, { expires: sessionExpires, path: '/', domain: getCookieDomain() });
};

export const setActivationCookie = code => {
  if (!code) {
    return;
  }
  const inThreeHours = new Date(new Date().getTime() + 3 * 60 * 60 * 1000);
  cookie.set(COOKIE_KEYS.ACTIVATION_CODE, code, { expires: inThreeHours, path: '/', domain: getCookieDomain() });
};
