import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input } from '@bonnierbroadcasting/cmore-ui';
import Flash from '../common/Flash';
import { AlignCenter } from '../common/styled/Page';
import { FormStyled } from '../common/styled/Forms';
import i18n from './i18n';
import { SuccessContainer } from './style';
import { withRouter } from 'react-router';

class ForgotPassword extends Component {
  state = {
    username: this.props.location.state ? this.props.location.state.usernameFromLocation : '',
  };

  canBeSubmitted = () => {
    const { username } = this.state;
    return username.length > 0;
  };

  handleSubmit = e => {
    e.preventDefault();
    if (!this.canBeSubmitted()) {
      return false;
    }
    const { username } = this.state;
    this.props.onForgotPassword(username);
  };

  render() {
    const isEnabled = this.canBeSubmitted();

    const { username } = this.state;
    const { success, error } = this.props;
    return success ? (
      <SuccessContainer>
        <h1> {i18n('successHeader')}</h1>
        <p>{i18n('successText')}</p>
        <Link
          style={{ display: 'block', marginTop: 36 }}
          to={{ pathname: '/login', state: { usernameFromLocation: username } }}
        >
          {i18n('backButton')}
        </Link>
      </SuccessContainer>
    ) : (
      <React.Fragment>
        <h1>{i18n('header')}</h1>
        <p style={{ maxWidth: 400, margin: '0 auto 16px auto' }}>{i18n('text')}</p>
        <FormStyled>
          <Flash success={success} error={error} />
          <Input
            placeholder="E-postadress"
            type="email"
            onChange={e => this.setState({ username: e.target.value })}
            value={username}
          />
          <br />
          <AlignCenter>
            <Button disabled={!isEnabled} onClick={this.handleSubmit} type="submit" loading={this.props.loading}>
              {' '}
              {i18n('submitButton')}{' '}
            </Button>
            <Link
              to={{ pathname: '/login', state: { usernameFromLocation: username } }}
              style={{ display: 'block', marginTop: 36 }}
            >
              {i18n('backButton')}
            </Link>
          </AlignCenter>
        </FormStyled>
      </React.Fragment>
    );
  }
}

ForgotPassword.propTypes = {
  success: PropTypes.string,
  error: PropTypes.string,
  onForgotPassword: PropTypes.func,
};

export default withRouter(ForgotPassword);
