import PropTypes from 'prop-types';
import React from 'react';
import CompleteProfileForm from './CompleteProfileForm';
import i18n from './i18n';

const CompleteProfile = ({ disabled, error, onCompleteProfile, loading }) => (
  <React.Fragment>
    <h1>{i18n('header')}</h1>
    <p>{i18n('text')}</p>
    <CompleteProfileForm disabled={disabled} onCompleteProfile={onCompleteProfile} loading={loading} />
  </React.Fragment>
);

CompleteProfile.propTypes = {
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onCompleteProfile: PropTypes.func.isRequired,
};

export default CompleteProfile;
