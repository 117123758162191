import styled, { keyframes } from 'styled-components';
import React, { FC } from 'react';

function SpinnerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#spinner_svg__clip0)">
        <path
          d="M10 2.5a7.5 7.5 0 105 13.09"
          stroke="#fff"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="spinner_svg__clip0">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export type SpinnerProps = {
  size: number;
};

const spin = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div<SpinnerProps>`
  pointer-events: none;
  animation: ${spin} 2s linear infinite;
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: auto;
  }
  width: ${({ size }) => size}px;
`;

const Spinner: FC<SpinnerProps> = ({ size, ...styleProps }) => (
  <SpinnerWrapper size={size} {...styleProps}>
    <SpinnerIcon />
  </SpinnerWrapper>
);
export default Spinner;
