import config from 'config';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormStyled } from '../common/styled/Forms';
import { Button, Checkbox } from '@bonnierbroadcasting/cmore-ui';
import i18n from './i18n';
import globalI18n from '../globalI18n';
import { AlignCenter } from '../common/styled/Page';

class CompleteProfileForm extends Component {
  state = {
    acceptedTerms: '',
    checked: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    const user = {
      acceptedCmoreTerms: this.state.acceptedTerms,
    };
    this.props.onCompleteProfile(user);
  };

  handleCheckbox = e => {
    this.setState({
      checked: !this.state.checked,
      acceptedTerms: config.acceptedTermsVersion,
    });
  };
  render() {
    const isDisabled = !this.state.checked;
    return (
      <FormStyled>
        <Checkbox
          checked={this.state.checked}
          value={this.state.acceptedTerms}
          onChange={this.handleCheckbox}
          name="acceptedTerms"
          id="acceptedTerms"
          small
        >
          {globalI18n('termsText', {
            termsLink: (
              <a rel="noopener noreferrer" target="_blank" href={globalI18n('termsUrl')}>
                {globalI18n('termsLinkText')}
              </a>
            ),
          })}
        </Checkbox>
        <AlignCenter>
          <Button
            loading={this.props.loading}
            type="submit"
            style={{ display: 'block', margin: '16px auto' }}
            disabled={this.props.disabled || isDisabled}
            onClick={this.handleSubmit}
          >
            {i18n('submitButton')}
          </Button>
          <Link style={{ display: 'inline-block' }} to={'/login'}>
            {i18n('backButton')}
          </Link>
        </AlignCenter>
      </FormStyled>
    );
  }
}

CompleteProfileForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onCompleteProfile: PropTypes.func.isRequired,
};

export default CompleteProfileForm;
