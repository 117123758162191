export const getCookieDomain = (hostname = window.location.hostname) => {
  const [, ...rest] = hostname.split('.');
  return rest.join('.');
};

export const getCountry = () => {
  if (/\.no$/.test(window.location.hostname)) {
    return 'no';
  }
  if (/\.dk$/.test(window.location.hostname)) {
    return 'dk';
  }
  return 'se';
};

export const getSite = () => {
  if (/\.no$/.test(window.location.hostname)) {
    return 'CMORE_NO';
  }
  if (/\.dk$/.test(window.location.hostname)) {
    return 'CMORE_DK';
  }
  return 'CMORE_SE';
};

export const getCmoreBaseUrl = () => {
  const origin = window.location.origin;

  if (origin.includes('dev')) {
    return `https://dev-www.cmore.${getCountry()}`;
  }
  if (origin.includes('beta1')) {
    return `https://www.beta1.cmore-stage.${getCountry()}`;
  }
  if (origin.includes('stage1')) {
    return `https://www.stage1.cmore-stage.${getCountry()}`;
  }
  return `https://www.cmore.${getCountry()}`;
};
