import config from 'config';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ACTIVATE_DEVICE from 'graphql/activateDevice';
import GENERATE_CODE from 'graphql/generateCode';
import VALIDATE_CODE from 'graphql/validateCode';
const serviceUrl = `https://${config.activationHost}?client=cmore-activation-web`;

const client = new ApolloClient({
  link: createHttpLink({ uri: serviceUrl }),
  cache: new InMemoryCache(),
});

const ActivationService = {
  validate: async activationCode => {
    const {
      data: { valid },
    } = await client.query({
      query: VALIDATE_CODE,
      variables: {
        activationCode,
      },
    });
    return valid;
  },
  activate: async (activationCode, token) => {
    return client.mutate({
      mutation: ACTIVATE_DEVICE,
      variables: {
        activationCode,
        token,
      },
    });
  },
  generate: async deviceId => {
    return client.mutate({
      mutation: GENERATE_CODE,
      variables: {
        deviceId,
      },
    });
  },
};

export default ActivationService;
