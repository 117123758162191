import styled from 'styled-components';

export const Img = styled.img`
 display: block;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 7.13rem;
`;

export const Banner = styled.div`
    box-shadow: 0 0.3rem 0.4rem rgb(0 0 0 / 13%);
    padding-left: 0.63rem;
    padding-right: 0.63rem;
    position: relative;
`;