import getTranslation from '../lib/getTranslation';

const translations = {
  header: {
    se: 'Komplettera dina uppgifter',
    dk: 'Indtast dine oplysninger',
    no: 'Fullfør med dine opplysninger'
  },
  text: {
    se: 'Du behöver acceptera våra användarvillkor',
    dk: 'Du skal acceptere vores brugerbetingelser',
    no: 'Du må akseptere brukervilkårene'
  },
  submitButton: {
    se: 'Aktivera',
    dk: 'Aktiver',
    no: 'Aktiver'
  },
  backButton: {
    se: 'Tillbaka',
    dk: 'Tilbage',
    no: 'Tilbake'
  },
};

export default getTranslation(translations);
