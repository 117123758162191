import React, { Component } from 'react';
import config from 'config';
import _get from 'lodash/get';
import cookie from 'js-cookie';
import GlobalStyles from './styles/global';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import AccountClient from '@bonnierbroadcasting/account-client';
import logo from './images/logo.svg';
import { Helmet } from 'react-helmet';
// import Authenticate from './Authenticate';
import Login from './Login';
import ActivationService from './lib/ActivationService';
import LoginGatewayActivationService from './lib/LoginGatewayActivationService';
import ForgotPassword from './ForgotPassword';
import Done from './Done';
import CompleteProfile from './CompleteProfile';
import EnterCode from './EnterCode';
import { PageContainer } from './common/styled/Page';
import { Img, Banner } from './common/styled/SiteLogo';
import i18n from './globalI18n';
// import FAQLink from './common/FAQLink';
import { setCmoreCredentials, setActivationCookie, COOKIE_KEYS } from './lib/cookie';
import { getSite } from './lib/site';
import { v4 as uuidv4 } from 'uuid';

import { getCookieDomain } from 'lib/site';

const mapError = errorString => {
  let key = '';
  switch (errorString) {
    case 'error.account.0':
      key = 'errors.generalError';
      break;
    case 'error.account.10':
      key = 'errors.invalidCredentials';
      break;
    case 'error.account.11':
      key = 'errors.invalidCode';
      break;
    case 'error.account.18':
      key = 'errors.userNameAlreadyInUse';
      break;
    case 'error.account.21':
      key = 'errors.usernameNotFound';
      break;
    default:
      key = 'errors.generalError';
  }
  return key;
};

const accountClient = new AccountClient(`${config.accountHost}?client=cmore-activation-web`);

class App extends Component {
  constructor(_, context) {
    super();
    // const domain = document.location.origin;
    var country = "SE";

    this.state = {
      // Activation code from EnterCode.
      code: null,
      // Form disabled indicator, true while doing asyncronous operations.
      disabled: false,
      // Something went wrong, explanation resides here.
      error: null,
      // Success message.
      success: null,
      // Authenticated user.
      user: '',
      /*
       * On the first request, the only allowed path is the root. Therefore
       * make sure to redirect the user while trying to access another page
       * initially.
       */
      location: null,
      initialRedirect: context.router.history.location.pathname !== '/',
      // Wating for async call
      loading: false,
      // LoginGatewayFlow
      useLoginGateway: false,
      country: country
    };
  }

  componentWillMount() {
    // Check if successfull Telia-login, and activate
    const teliaLogin = window.location.search.includes('teliaLogin=true');
    const sessionToken = cookie.get(COOKIE_KEYS.SESSION_TOKEN);
    const activationSessionToken = cookie.get(COOKIE_KEYS.ACTIVATION_SESSION_TOKEN);
    const activationCode = cookie.get(COOKIE_KEYS.ACTIVATION_CODE);
    if (teliaLogin && sessionToken && activationSessionToken && activationCode) {
      this.nextComponent('/done', {});
      this.setState(
        {
          code: activationCode,
          tvToken: activationSessionToken,
          userHasActiveOrders: true,
        },
        this.handleActivate
      );
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.props.location.pathname !== this.state.location) {
      this.setState({
        location: this.props.location.pathname,
        error: null,
      });
    }
    if (prevState.initialRedirect) {
      this.setState({ initialRedirect: false });
    }
  }

  disable(callback) {
    const nextState = {
      disabled: true,
      error: null,
    };

    this.setState(nextState, callback);
  }

  handleUserFromSessionCookie = async () => {
    const sessionToken = cookie.get(COOKIE_KEYS.SESSION_TOKEN);
    if (sessionToken) {
      const response = await accountClient.getUserInformation(sessionToken);
      const user = _get(response, 'data.me.user');

      // cometuser means generated TVE-account and should not be pre-filled.
      if (_get(user, 'email').includes('@cometuser.june.se')) {
        return '';
      }

      return user;
    }

    return '';
  };

  handleEnterCode = code => {

    console.log(this.state.country)

    const useLoginGateway = code[0] < 'I';
    var configuredActivationService;
    if (useLoginGateway) {
      this.setState({ useLoginGateway: true });
      configuredActivationService = LoginGatewayActivationService
    } else {
      configuredActivationService = ActivationService
    }
    this.disable(async () => {
      try {
        const valid = await configuredActivationService.validate(code, this.state.country);
        if (!valid) {
          throw new Error('invalid code');
        }
        setActivationCookie(code);
        const user = await this.handleUserFromSessionCookie(code);
        this.nextComponent('/login', { code, user });
      } catch (err) {
        this.handleError(mapError('error.account.11'));
      }
    });
  };

  handleActivate() {
    this.disable(async () => {
      try {
        const { code, tvToken, userHasActiveOrders } = this.state;
        if (userHasActiveOrders) {
          await ActivationService.activate(code, tvToken);
          cookie.remove(COOKIE_KEYS.ACTIVATION_SESSION_TOKEN, { path: '/', domain: getCookieDomain() });
          cookie.remove(COOKIE_KEYS.ACTIVATION_CODE, { path: '/', domain: getCookieDomain() });
        }
        this.nextComponent('/done', {});
      } catch (err) {
        const errorCode = err.code || 0;
        this.handleError(mapError(`error.account.${errorCode}`));
      }
    });
  }

  handleCheckUserExists = async username => {
    if (this.state.useLoginGateway) {
      this.nextComponent('/login', { username: username });
    } else {
      this.disable(async () => {
        try {
          this.setState({ loading: true });
          const response = await accountClient.doesUserExist(username);
          const { userExists } = response.data;

          if (userExists) {
            this.nextComponent('/login', { username: username });
          }
        } catch (err) {
          const errorCode = err.code || 0;
          this.handleError(mapError(`error.account.${errorCode}`));
        }

        this.setState({ loading: false });
      });
    }
  };

  handleAuthenticate = async (username, password) => {
    if (this.state.useLoginGateway) {
      this.disable(async () => {
        this.setState({ loading: true });
        // Log in web
        const deviceId = 'ACTIVATION_' + uuidv4();

        const responseLoginWebUser = await LoginGatewayActivationService.loginWeb(username, password, deviceId, this.state.country);
        const body = await responseLoginWebUser.json();
        const code = cookie.get(COOKIE_KEYS.ACTIVATION_CODE);

        // Set token in browser
        const sessionToken = body.accessToken;
        setCmoreCredentials(sessionToken);


        const responseFromLoginGateway = await LoginGatewayActivationService.loginDevice(username, password, code, deviceId, this.state.country);
        if (responseFromLoginGateway.status === 200) {
          const responseFromEngagementInfo = await LoginGatewayActivationService.engagementInfo(sessionToken);
          var engagementInfo = await responseFromEngagementInfo.json();

          var channels = engagementInfo.channelIds;
          var stores = engagementInfo.stores;
          const userHasActiveOrders = (channels.length + stores.length) > 0;

          this.setState({
            undefined,
            userHasActiveOrders,
            sessionToken,
          });

          if (userHasActiveOrders) {
            this.nextComponent('/done', {});
          } else {
            const inTwoHours = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
            cookie.set(COOKIE_KEYS.ACTIVATION_SESSION_TOKEN, sessionToken, {
              path: '/',
              expires: inTwoHours,
              domain: getCookieDomain(),
            });
            this.nextComponent('/done', {});

          }
        } else if (responseFromLoginGateway.status === 401) {
          this.handleError('errors.invalidCredentials');
        } else {
          this.handleError('errors.generalError');
        }


        this.setState({ loading: false });
      });
    } else {
      const login = async (username, password) => {
        return await accountClient.login(username, password, getSite());
      };

      this.disable(async () => {
        try {
          this.setState({ loading: true });
          const responseForSessionToken = await login(username, password);
          // Login again to create additional token for the TV
          const responseForTvToken = await login(username, password);

          const tvToken = _get(responseForTvToken, 'data.login.session.token');
          const sessionToken = _get(responseForSessionToken, 'data.login.session.token');
          const userResponse = await accountClient.getUserInformation(sessionToken);
          const user = _get(userResponse, 'data.me.user');
          const currentOrders = _get(userResponse, 'data.me.currentOrders') || [];
          const userHasActiveOrders = currentOrders.length > 0;
          setCmoreCredentials(sessionToken);
          this.setState({
            user,
            userHasActiveOrders,
            tvToken,
          });
          if (!userHasActiveOrders) {
            const inTwoHours = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
            cookie.set(COOKIE_KEYS.ACTIVATION_SESSION_TOKEN, tvToken, {
              path: '/',
              expires: inTwoHours,
              domain: getCookieDomain(),
            });
          }

          if (!user.acceptedCmoreTerms) {
            this.nextComponent('/complete-profile', {});
          } else if (userHasActiveOrders) {
            this.handleActivate();
          } else {
            this.nextComponent('/done', {});
          }
        } catch (err) {
          const errorCode = err.code || 0;
          this.handleError(mapError(`error.account.${errorCode}`));
        }

        this.setState({ loading: false });
      });
    }
  };

  handleCompleteProfile = user => {
    const token = cookie.get(COOKIE_KEYS.SESSION_TOKEN);
    this.disable(async () => {
      try {
        this.setState({ loading: true });
        await accountClient.update(token, user);
        this.handleActivate();
      } catch (err) {
        const errorCode = err.code || 0;
        this.handleError(mapError(`error.account.${errorCode}`));
      }

      this.setState({ loading: false });
    });
  };

  handleForgotPassword = async username => {
    const site = 'CMORE_SE';
    this.disable(async () => {
      try {
        this.setState({ loading: true });
        await accountClient.forgotPassword(username, site);

        this.setState({
          disabled: false,
          error: null,
          success: true,
        });
      } catch (err) {
        const errorCode = err.code || 0;
        this.handleError(mapError(`error.account.${errorCode}`));
      }

      this.setState({ loading: false });
    });
  };

  // handle translation
  handleError(err, ...key) {
    const error = i18n(err);
    this.setState({
      disabled: false,
      error: error,
    });
  }

  nextComponent(path, nextState) {
    const { router } = this.context;
    const newState = Object.assign(nextState, {
      disabled: false,
      error: null,
    });

    this.setState(newState, () => {
      router.history.push(path);
    });
  }

  render() {
    if (this.state.initialRedirect) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <Helmet
          title={i18n('title')}
          meta={[
            {
              name: 'description',
              content: i18n('description'),
            },
          ]}
        />
        <GlobalStyles />
        <Banner>
          <Img alt="" src={logo} />
        </Banner>
        <Switch>
          <PageContainer>
            <Route
              exact
              path="/"
              render={() => (
                <EnterCode disabled={this.state.disabled} error={this.state.error} onEnterCode={this.handleEnterCode} />
              )}
            />
            {/* <Route
              exact
              path="/authenticate"
              render={() => (
                <Authenticate
                  user={this.state.user}
                  loading={this.state.loading}
                  disabled={this.state.disabled}
                  error={this.state.error}
                  onAuthenticate={this.handleCheckUserExists}
                />
              )}
            /> */}

            <Route
              exact
              path="/login"
              render={() => (
                <Login
                  loading={this.state.loading}
                  disabled={this.state.disabled}
                  error={this.state.error}
                  onAuthenticate={this.handleAuthenticate}
                  username={this.state.username}
                />
              )}
            />
            <Route
              exact
              path="/forgot-password"
              render={() => (
                <ForgotPassword
                  loading={this.state.loading}
                  error={this.state.error}
                  success={this.state.success}
                  onForgotPassword={this.handleForgotPassword}
                />
              )}
            />
            <Route
              exact
              path="/complete-profile"
              render={() => (
                <CompleteProfile
                  loading={this.state.loading}
                  disabled={this.state.disabled}
                  error={this.state.error}
                  onCompleteProfile={this.handleCompleteProfile}
                />
              )}
            />
            <Route
              exact
              path="/done"
              render={() => <Done userHasActiveOrders={this.state.userHasActiveOrders} error={this.state.error} />}
            />
            {/* <FAQLink /> */}
          </PageContainer>
        </Switch>
      </React.Fragment>
    );
  }
}

App.contextTypes = { router: PropTypes.object.isRequired };

export default withRouter(App);
