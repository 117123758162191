import styled from 'styled-components';
import media from '../../styles/media_queries';
import Button from '../../Button/Button';

export const PageContainer = styled.div`
  width: 90%;
  margin: auto;
  max-width: 360px;

  ${media.maxWidth`
  max-width: initial;
`}

max-width: 900px;

  ${media.tablet`
    width: 100%;
  `}
`;
export const AlignCenter = styled.div`
  text-align: center;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledGrayButton = styled(StyledButton)`
  margin-top: 1rem;
  background-color: #A0A0A0;
`;

export const Header = styled.h1`
    font-size: 24px;
    line-height: 28px;
    margin: 0px 0px 24px;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
`;