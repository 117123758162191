import getTranslation from '../lib/getTranslation';

const translations = {
  header: {
    se: 'Ange koden som visas på din enhet',
    dk: 'Indtast aktiveringskoden som står på din Tv-skærm',
    no: 'Skriv inn aktiveringskoden som står på TVen din'
  },
  nextButton: {
    se: 'Fortsätt',
    dk: 'Fortsæt',
    no: 'Fortsette',
  },
};

export default getTranslation(translations);
