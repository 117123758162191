import styled from 'styled-components';
import React, { forwardRef, useState } from 'react';
import media from '../styles/media_queries';


function SvgPasswordVisible(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
        stroke="#272727"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M12.094 10.5a1.5 1.5 0 011.5 1.5"
        stroke="#272727"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 12c0 .75 4.365 6 9.75 6s9.75-5.25 9.75-6c0-.435-1.467-2.382-3.75-3.942C16.346 6.928 14.263 6 12 6c-5.385 0-9.75 5.25-9.75 6z"
        stroke="#272727"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </svg>
  );
}
function SvgPasswordHidden(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M16 21a5 5 0 100-10 5 5 0 000 10z"
        stroke="#272727"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M16.125 14a2 2 0 012 2"
        stroke="#272727"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 16c0 1 5.82 8 13 8s13-7 13-8c0-.58-1.956-3.176-5-5.256C21.794 9.236 19.017 8 16 8 8.82 8 3 15 3 16zM26 6L6 26"
        stroke="#272727"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </svg>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  flex: 1 0 100%;
  height: 54px;
  background: white;
  border: 1px solid black;
  border-radius: 0;
  box-sizing: border-box;
  ${media.belowPhoneLandScape`
    height: 56px;
  `}
`;

const iconStyle = `
  flex: 0 0 24px;
  padding: 14px;
  z-index: 1;
  height: 24px;
`;

const StyledPasswordVisibleIcon = styled(SvgPasswordVisible)`
  ${iconStyle}
  ${media.belowPhoneLandScape`
    padding: 16px;
  `}
`;

const StyledPasswordHiddenIcon = styled(SvgPasswordHidden)`
  ${iconStyle}
  ${media.belowPhoneLandScape`
    padding: 16px;
  `}
`;

const StyledInput = styled.input<{ hasIcon: boolean; disabled?: boolean }>`
  font-size: 16px;
  line-height: 22px;
  flex: 0 1 auto;
  width: 100%;
  padding: 16px;
  appearance: none;
  outline: 0;
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  &::-ms-clear, &::-ms-reveal {
    display: none;
  }
  ${media.belowPhoneLandScape`
  font-size: 18px;
  line-height: 24px;
  `}

`;

const StyledLabel = styled.label`
  font-size: 14px;
  line-height: 18px;
  color: #8B8B8B;
  margin-bottom: 6px;
  flex: 1 0 100%;
  ${media.belowPhoneLandScape`
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
  `}
`;

export type InputProps = {
  'data-testid'?: string;
  id: string;
  label?: string;
  showPasswordIcon?: boolean;
} & Pick<
  JSX.IntrinsicElements['input'],
  | 'autoComplete'
  | 'autoFocus'
  | 'disabled'
  | 'onChange'
  | 'onKeyDown'
  | 'onPaste'
  | 'onInput'
  | 'placeholder'
  | 'type'
  | 'value'
  | 'inputMode'
  | 'pattern'
  | 'maxLength'
>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      'data-testid': testId,
      id,
      label,
      autoComplete,
      autoFocus,
      disabled,
      onChange,
      onInput,
      placeholder,
      type,
      value,
      showPasswordIcon = false,
      ...rest
    },
    ref
  ) => {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const togglePasswordVisiblity = () => {
      setPasswordVisible(!passwordVisible);
    };
    const inputType = showPasswordIcon ? (passwordVisible ? 'text' : 'password') : type;

    return (
      <StyledWrapper {...rest}>
        {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
        <StyledInputWrapper>
          <StyledInput
            ref={ref}
            data-testid={testId}
            id={id}
            aria-disabled={disabled}
            autoComplete={autoComplete}
            // using autoFocus prop is not recommended from a11y stand point
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            disabled={disabled}
            onChange={onChange}
            onInput={onInput}
            placeholder={placeholder}
            value={value}
            type={inputType}
            hasIcon={showPasswordIcon}
          />
          {showPasswordIcon &&
            (passwordVisible ? (
              <StyledPasswordHiddenIcon onClick={togglePasswordVisiblity} />
            ) : (
              <StyledPasswordVisibleIcon onClick={togglePasswordVisiblity} />
            ))}
        </StyledInputWrapper>
      </StyledWrapper>
    );
  }
);

Input.displayName = 'Input';

export default Input;