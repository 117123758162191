import { css } from 'styled-components';
const LARGE_DESKTOP = '1280px';
const DESKTOP = '1023px';
const TABLET = '767px';
const PHONE_LANDSCAPE_BREAKPOINT = '480px';
const MAX_WIDTH_BREAKPOINT = '750px';

const media = {
  tablet: (...args) => css`
    @media (min-width: ${TABLET}) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (min-width: ${DESKTOP}) {
      ${css(...args)}
    }
  `,
  largeDesktop: (...args) => css`
    @media (min-width: ${LARGE_DESKTOP}) {
      ${css(...args)}
    }
  `,
  belowPhoneLandScape: (...args) => css`
    @media (max-width: ${PHONE_LANDSCAPE_BREAKPOINT}) {
      ${css(...args)}
    }
  `,
  phoneLandscape: (...args) => css`
    @media (min-width: ${PHONE_LANDSCAPE_BREAKPOINT}) {
      ${css(...args)}
    }
  `,
  maxWidth: (...args) => css`
    @media (min-width: ${MAX_WIDTH_BREAKPOINT}) {
      ${css(...args)}
    }
  `,
};

export default media;
