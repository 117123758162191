import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0%,
  50%,
  100% {
    transform: scale3d(1, 1, 1);
  }
  75% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}
`;

const dash = keyframes`
  0% {
    stroke-dashoffset: 1000;
    fill-opacity: 0;
  }
  25% {
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }
}
`;

export const CheckMarkAnimated = styled.svg`
  animation: ${pulse} 2500ms ease;
  overflow: auto;

  > path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: ${dash} 2500ms ease;
    stroke: url(#gradient);
    fill: url(#gradient);
  }
`;
