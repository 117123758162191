import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { EnterCodeContainer, InnerDiv, InputStyled, ErrorMessage } from './style';
import i18n from './i18n';
import queryString from 'query-string';
import { AlignCenter,  StyledButton } from '../common/styled/Page';

const elementValue = el => {
  if (el.value.length > 1) {
    return el.value[el.value.length - 1];
  }
  return el.value;
};

const makeArray = (len, val) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr[i] = { value: val[i] };
  }
  return arr;
};

export default function EnterCode({ disabled, onEnterCode, error}) {
  const { userCode } = queryString.parse(window.location.search);
  const codeParam = userCode && userCode.length === 6 ? userCode : []
  const [inputFields] = useState(makeArray(6, codeParam));
  const [codeLength, setCodeLength] = useState(0);

  const getCode = useCallback(() => {
    return inputFields.map(x => {
      if ( x !== null) {
        return x.value;
      }
      return x;
    });
  },[inputFields]);

  useEffect(() => {
    const code = getCode().join('');
    setCodeLength(code.length)
  },[setCodeLength, getCode])


  const isComplete = (code) => {
    setCodeLength(code.length)
    return code.length === inputFields.length;
  }

  const handleClick = (e) => {
    e.target.value = '';
  }

  const handleChange = (e) => {
    const nextVal = elementValue(e.target);
    const code = getCode().join('');

    // WARNING: This is due to a nexus/andriod bug.
    // The value is set to all entered chars instead of just the last.
    e.target.value = nextVal;
    if (isComplete(code)) {
      e.target.blur();
      onEnterCode(code);
    } else if (e.target.value.length > 0) {
      const index = parseInt(e.target.id, 10);
      const nextRef = (index + 1) % inputFields.length;

      inputFields[nextRef].focus();
    }
  }

  const handleSubmit = (e) => {
    const code = getCode().join('');
    if (isComplete(code)) {
      e.target.blur();
      onEnterCode(code);
    } 
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      const index = parseInt(e.target.id, 10);
      const nextRef = index - 1;
      nextRef > -1 && inputFields[nextRef].focus();
    }
  }

    const inputs = getCode().map((value, i) => (
      <InputStyled
        autoCapitalize="true"
        autoFocus={i === 0}
        defaultValue={value}
        disabled={disabled}
        autoComplete="off"
        id={i}
        key={i}
        maxLength={1}
        name={`code-${i}`}
        data-test={`code-input-${i}`}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onClick={handleClick}
        ref={input => {
          inputFields[i] = input;
        }}
        type="text"
      />
    ));

    return (
      <Fragment>
        <EnterCodeContainer data-test={error ? 'invalid-code' : ''}>
          <p>{i18n('header')}</p>
          <InnerDiv>
            {inputs}
          </InnerDiv>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <AlignCenter>
          <StyledButton disabled={codeLength !== 6} variant="primary" isLoading={disabled} size="wide" onClick={(e) => handleSubmit(e)}>
            {i18n('nextButton')}
          </StyledButton>
        </AlignCenter>
        </EnterCodeContainer>
      
      </Fragment>
    );
  
}

EnterCode.propTypes = {
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onEnterCode: PropTypes.func.isRequired,
};
