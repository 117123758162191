import styled, { css } from 'styled-components';
import React from 'react';
import DumbSmartLink, { SmartLinkProps } from './DumbSmartLink';
import Spinner from './Spinner';
import media from '../styles/media_queries';
type StyledButtonProps = Pick<ButtonProps, 'variant' | 'size' | 'disabled'>;

const StyledButton = styled.button<{ as: 'span' | 'button' } & StyledButtonProps>`
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;

  display: inline-flex;
  box-sizing: border-box;

  outline: 0;
  border: 0;
  transition: 0.2s;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-weight: bold;

  color: #ffffff;

  height: 40px; 
  border-radius: 40px;
  padding: 0 32px;

  svg {
    /* width: 20px;
    height: 20px; */

    /* *[fill] {
      fill: #ffffff;
    }
    *[stroke] {
      stroke: #ffffff;
    } */
  }

  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return css`
          background-color: #990AE3;
        `;
      case 'secondary':
        return css`
          background-color: #00CC66;
        `;
      case 'ghost':
        return css`
          background: rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(6px);
        `;
    }
  }}

  ${({ size }) =>
    size !== 'small' &&
    media.belowPhoneLandScape`
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
      height: 48px;
      border-radius: 24px;

      svg {
        width: 16px;
        height: 16px;
      }
    `}
  
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 14px;
          line-height: 14px;
          font-weight: 700;
          text-transform: uppercase;
          height: 32px;
          min-width: 119px;
          border-radius: 20px;

          svg {
            width: 14px;
            height: 14px;
          }
        `;
      case 'wide':
        return css`
          padding-left: 64px;
          padding-right: 64px;
        `;
    }
  }}

  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
          &:active {
            opacity: 0.7;
          }
        `
      : css`
          opacity: 0.5;
        `}
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: auto;
  right: auto;
`;

type StyledWrapperProps = {
  isHidden?: boolean;
};

const StyledWrapper = styled.span<StyledWrapperProps>`
  display: flex;
  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
    `}
`;

type StyledContentProps = {
  hasLeftMargin: boolean;
};

const StyledContent = styled.span<StyledContentProps>`
  white-space: nowrap;
  display: block;
  margin-left: ${({ hasLeftMargin }) => (hasLeftMargin ? '8px' : 0)};
  align-self: center;
`;

export type ButtonProps = {
  'data-testid'?: string;
  children?: React.ReactNode;
  variant: 'ghost' | 'primary' | 'secondary';
  isLoading?: boolean;
  size?: 'small' | 'wide';
  disabled?: boolean;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
} & (Pick<JSX.IntrinsicElements['button'], 'type' | 'onClick'> & Pick<SmartLinkProps, 'href'>);

export default function Button({
  children,
  Icon,
  variant,
  isLoading,
  size,
  disabled,
  href,
  type,
  onClick,
  ...rest
}: ButtonProps) {
  return (
    <DumbSmartLink href={href}>
      <StyledButton
        as={href ? 'span' : 'button'}
        aria-disabled={disabled}
        disabled={disabled}
        variant={variant}
        size={size}
        type={type}
        onClick={onClick}
        {...rest}
      >
        {isLoading && <StyledSpinner size={20} />}

        <StyledWrapper isHidden={isLoading}>
          {Icon && <Icon />}
          {children && <StyledContent hasLeftMargin={!!Icon}>{children}</StyledContent>}
        </StyledWrapper>
      </StyledButton>
    </DumbSmartLink>
  );
}
