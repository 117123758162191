import styled from 'styled-components';
import media from '../styles/media_queries';

export const EnterCodeContainer = styled.div`
  width: auto;
  margin: 0 auto;
  text-align: center;
  max-width: 265px;
  padding-top: 80px;
  padding-left: 1rem;
  padding-right: 1rem;
  ${media.maxWidth`
    max-width: 275px;
  `}

  h1 {
    max-width: 275px;
    font-size: 18px;
    margin-bottom: 42px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ErrorMessage = styled.p`
    color: #FF3377;
    font-size: 12px;
    margin: -30px 0 15px 0;
    height: 15px;
    font-weight: normal;
`;

export const InnerDiv = styled.div`
  display: inline-block;
`;

export const InputStyled = styled.input`
  width: 32px;
  height: 48px;
  font-size: 32px;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 42px;
  -webkit-appearance: none;
  border-width: 1px;
  border-style: solid;
  padding: 0px;

  ${media.tablet`
    margin-bottom: 42px;
  `};
  &:focus {
    outline: none;
  }

  ${({ error }) =>
    error &&
    `
    color: #ff3334;
    border-bottom: 10px solid #ff3334;
  `}
`;
