import styled from 'styled-components';
import media from '../../styles/media_queries';
import { fontSizes } from '@bonnierbroadcasting/cmore-ui';

export const FormStyled = styled.form`
  border-radius: 5px;
  box-sizing: border-box;
  background-color: white;
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
  max-width: 400px;
  margin: auto;
  padding: 16px;

  ${media.tablet`
    padding: 32px;
  
  `}

  input {
    box-sizing: border-box;
  }
`;

export const FormInputInlineButton = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  padding: 0;
  background: transparent;
  color: black;
  font-size: ${fontSizes[200]};
  align-self: flex-end;
  grid-column: 3;
  cursor: pointer;
  padding-bottom: 4px;
  padding-left: 5px;
  &:hover {
    text-decoration: underline;
  }
`;
