import { css } from 'styled-components';
import PebbleEOT from './fonts/Pebble.eot';
import PebbleTTF from './fonts/Pebble.ttf';
import PebbleWOFF from './fonts/Pebble.woff';
import PebbleWOFF2 from './fonts/Pebble.woff2';

export default css`
  @font-face {
    font-family: 'Pebble';
    src: url(${PebbleEOT}); /* IE9 Compat Modes */
    src: url(${PebbleEOT}?#iefix) format('embedded-opentype'),
      /* IE6-IE8 */ url(${PebbleWOFF2}) format('woff2'),
      /* Super Modern Browsers */ url(${PebbleWOFF}) format('woff'),
      /* Pretty Modern Browsers */ url(${PebbleTTF}) format('truetype');
  }
`; 