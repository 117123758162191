import getTranslation from '../lib/getTranslation';

const translations = {
  header: {
    se: 'Glömt lösenordet',
    dk: 'Glemt kodeordet',
    no: 'Glemt passord'
  },
  submitButton: {
    se: 'Återställ lösenord',
    dk: 'Nulstil adgangskode',
    no: 'Tilbakestill passord'
  },
  backButton: {
    se: 'Tillbaka',
    dk: 'Tilbage',
    no: 'Tilbake'
  },
  text: {
    se: 'Fyll i din e-postadress nedan så skickar vi instruktioner för hur du återställer det.',
    dk: 'Indtast din e-mailadresse nedenfor, så sender vi dig instruktioner om, hvordan du gendanner det',
    no: 'Fyll inn e-postadressen din under så sender vi instruksjoner for tilbakestilling'
  },
  successHeader: {
    se: 'Sådär!',
    dk: 'Der går du!',
    no: 'Klart'
  },
  successText: {
    se: 'Invänta e-post från C More och följ sedan länken för att byta lösenord.',
    dk: 'Vent til email fra C More, og følg linket for at ændre adgangskode.',
    no: 'Følg linken i e-post fra C More for å bytte passord'
  },
};

export default getTranslation(translations);
