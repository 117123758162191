import PropTypes from 'prop-types';
import React from 'react';
import LoginForm from './LoginForm';
import Flash from '../common/Flash';


const Login = ({ disabled, error, onAuthenticate, username, loading }) => (
  <React.Fragment>
    <LoginForm disabled={disabled} onSubmit={onAuthenticate} username={username} loading={loading}>
      <Flash error={error} />
    </LoginForm>
  </React.Fragment>
);

Login.propTypes = {
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onAuthenticate: PropTypes.func.isRequired,
};

export default Login;
