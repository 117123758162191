import getTranslation from './lib/getTranslation';

const translations = {
  description: {
    se: 'Telia TV',
    dk: 'Telia TV',
    no: 'Telia TV'
  },
  general: {
    next: {
      se: 'Nästa',
      dk: 'Næste',
      no: 'Neste'
    },
    show: {
      se: 'Visa',
      dk: 'Vis',
      no: 'Vis'
    },
    hide: {
      se: 'Göm',
      dk: 'Gem',
      no: 'Skjul'
    },
    or: {
      se: 'eller',
      dk: 'eller',
      no: 'eller'
    },
    password: {
      se: 'Lösenord',
      dk: 'Kodeord',
      no: 'Passord'
    },
    username: {
      se: 'Användarnamn',
      dk: 'Email',
      no: 'E-postadresse'
    },
    email: {
      se: 'E-postadress',
      dk: 'Email',
      no: 'E-postadresse'
    },
    firstName: {
      se: 'Förnamn',
      dk: 'Fornavn',
      no: 'Fornavn'
    },
    lastName: {
      se: 'Efternam',
      dk: 'Efternavn',
      no: 'Etternavn'
    },
  },
  title: {
    se: 'Telia TV',
    dk: 'Telia TV',
    no: 'Telia TV'
  },
  cmoreUrl: {
    se: 'https://www.cmore.se',
    dk: 'https://www.cmore.dk',
    no: 'https://www.cmore.no'
  },
  termsText: {
    se:
      'Jag godkänner {termsLink} och samtycker till att min ångerrätt upphör om/när jag påbörjar streaming av innehåll i tjänsten.',
    dk:
      'Jeg accepterer {termsLink} og accepterer dernæst at min opsigelsesret ophører, hvis / når jeg begynder at streame indhold i tjenesten.',

      no: 'Jeg godkjenner {termsLink} og samtykker at angreretten opphører når jeg starter å se på innhold i tjenesten. '
  },
  termsLinkText: {
    se: 'C Mores användarvillkor',
    dk: 'C Mores Vilkår for brug',
    no: 'C Mores vilkår for bruk'
  },
  faqText: {
    se: 'Behöver du hjälp? {faqLink}',
    dk: 'Brug for hjælp? {faqLink}',
    no: 'Trenger du hjelp? {faqLink}'
  },
  faqLinkText: {
    se: 'Läs mer i vår FAQ',
    dk: 'Læs mere i vores FAQ',
    no: 'Les mer i vår FAQ'
  },
  faqUrl: {
    se: 'https://www.cmore.se/artiklar/vanliga-fragor',
    dk: 'https://www.cmore.dk/artikler/faq',
    no: 'https://www.cmore.no/artikler/vanlige-sporsmal'
  },
  termsUrl: {
    se: 'https://www.cmore.se/artiklar/anvandarvillkor',
    dk: 'https://www.cmore.dk/artikler/brugervilkar?_ga=2.246489348.1227772138.1549894874-227100085.1549894874',
    no: 'https://www.cmore.no/artikler/brukervilkar'
  },
  changeEmail: {
    se: 'Byt e-post',
    dk: 'Skift emailadresse',
    no: 'Bytt e-post'
  },
  errors: {
    invalidCode: {
      se: 'Tyvärr är koden inte korrekt. Var god försök igen.',
      dk: 'Aktiveringskoderne stemmer ikke overens med hinanden. Prøv igen.',
      no: 'Aktiveringskodene samsvarer ikke. Prøv igjen.'
    },
    invalidCredentials: {
      se: 'Felaktig e-postadress eller lösenord.',
      dk: 'Forkert e-mailadresse eller adgangskode.',
      no: 'Feil e-postadresse eller passord'
    },
    usernameNotFound: {
      se: 'Vi hittade ingen användare kopplad till den här e-posten.',
      dk: 'Der er ingen bruger med den email, du har angivet',
      no: 'Vi fant ingen brukere koblet til denne e-posten'
    },
    userNameAlreadyInUse: {
      se: 'Det verkar som att du redan har ett konto hos oss – testa att logga in',
      dk: 'Det verkar som att du redan har ett konto hos oss – testa att logga in',
      no: 'Det ser ut som at du allerede har en konto hos oss –  forsøk å logge inn'
    },
    generalError: {
      se: 'Hoppsan! Något gick fel, kontrollera dina uppgifter och försök igen.',
      dk: 'Ups! Noget gik galt, tjek dine detaljer og prøv igen',
      no: 'Kontroller innloggingsdetaljene dine og prøv igjen'
    },
  },
};

export default getTranslation(translations);
