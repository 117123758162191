import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const ErrorMessage = styled.p`
  color: #FF3377;
  font-size: 12px;
`;

class Flash extends Component {
  hasMessage() {
    return this.props.error || this.props.success;
  }

  render() {
    if (!this.hasMessage()) {
      return null;
    }
    let message = this.props.success ? this.props.success : this.props.error;
    return (
      <ErrorMessage success={this.props.success} error={this.props.error}>
        {message}
      </ErrorMessage>
    );
  }
}

Flash.defaultProps = {
  error: null,
  success: null,
};

Flash.propTypes = {
  error: PropTypes.string,
  success: PropTypes.string,
};

export default Flash;
