import React from 'react';
import styled from 'styled-components';

export type SmartLinkProps = {
  'data-testid'?: string;
  children: React.ReactNode;
  title?: string;
} & (
  | { href?: string | null }
  | {
      href: string;
      onClick?: () => void;
    }
);

const StyledA = styled.a`
  text-decoration: none;
`;

export default function SmartLink({ href, children, ...props }: SmartLinkProps) {
  if (!href) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <StyledA href={href} {...props}>
      {children}
    </StyledA>
  );
}
