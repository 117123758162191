import styled from 'styled-components';
import { FormStyled } from '../common/styled/Forms';
import { boden } from '../common/styled/colors';

export const SuccessContainer = styled(FormStyled)`
  h1 {
    color: ${boden};
    font-weight: 400;
  }
`;
