import getTranslation from '../lib/getTranslation';

const translations = {
  header: {
    se: 'Logga in',
    dk: 'Login',
  },
  submitButton: {
    se: 'Logga in',
    dk: 'Login',
  },
  loginOptions: {
    se: 'Inloggningsalternativ',
    dk: 'Inloggningsalternativ',
  },
  text: {
    se: 'Kommer du inte in?',
    dk: 'Kan du ikke logge ind?',
  },
  resetPassword: {
    se: 'återställ lösenord',
    dk: 'nulstil kodeord',
  },
  createAccount: {
    se: 'skapa nytt konto',
    dk: 'opret ny konto',
  },
  forgotPassword: {
    se: 'Glömt lösenordet?',
    dk: 'Glemt adgangskoden?',
  },
};

export default getTranslation(translations);
