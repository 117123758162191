import React from 'react';
import PropTypes from 'prop-types';
import { CheckMarkAnimated } from './styles';

const CheckMarkAnimatedIcon = ({ height, width }) => (
  <CheckMarkAnimated width={width || '111px'} height={height || '111px'} viewBox="0 0 111 111">
    <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
      <stop stopColor="#d4fc79" offset="0%" />
      <stop stopColor="#2af598" offset="100%" />
    </linearGradient>
    <path
      transform="translate(-132, -177)"
      d="M213.550501,183.506133 C215.805382,184.705074 216.66139,187.50495 215.462448,189.75983 C214.263507,192.014711 211.463631,192.870719 209.208751,191.671777 C186.659946,179.682365 158.66119,188.242444 146.671777,210.791249 C134.682365,233.340054 143.242444,261.33881 165.791249,273.328223 C188.340054,285.317635 216.33881,276.757556 228.328223,254.208751 C235.198315,241.287986 235.494742,227.527931 229.487654,214.483944 C228.419397,212.164294 229.433851,209.417854 231.7535,208.349596 C234.073149,207.281339 236.81959,208.295792 237.887847,210.615442 C245.090423,226.255353 244.734468,243.052184 236.493867,258.550501 C222.106573,285.609067 188.508064,295.881162 161.449499,281.493867 C134.390933,267.106573 124.118838,233.508064 138.506133,206.449499 C152.893427,179.390933 186.491936,169.118838 213.550501,183.506133 Z M167.202884,227.036415 L185.596096,247.644231 L224.443812,189.346764 C225.843715,187.115419 228.68083,186.512465 230.780685,188.000028 C232.88054,189.487591 233.447963,192.502362 232.04806,194.733706 L189.398219,259.091484 C187.589477,261.974488 183.888286,261.747015 181.793972,259.091484 L159.598636,232.423358 C158.198733,230.192013 158.766156,227.177243 160.866011,225.689679 C162.965866,224.202116 165.802981,224.80507 167.202884,227.036415 Z"
    />
  </CheckMarkAnimated>
);

CheckMarkAnimatedIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

CheckMarkAnimatedIcon.defaultProps = {
  width: '111px',
  height: '111px',
};

export default CheckMarkAnimatedIcon;
