import reactI18n from '@bonnierbroadcasting/bbr-react-i18n';

const getCountry = (hostname = window.location.hostname) => {
  if (/\.dk$/.test(hostname)) {
    return 'dk';
  } else if (/\.no$/.test(hostname)) {
    return 'no'
  }
  return 'se';
};
const getTranslation = obj => reactI18n(obj, getCountry());

export default getTranslation;
