import getTranslation from '../lib/getTranslation';

const translations = {
  header: {
    se: 'Klart!',
    dk: 'Klar!',
    no: 'Ferdig!'
  },
  text: {
    se: 'Nu kan du börja streama på din TV',
    dk: 'Nu kan du streame på dit TV',
    no: 'Nå kan du begynne å se på din TV'
  },
  textNoSubscription: {
    se: 'För att kunna titta på vårt innehåll behöver du skaffa ett paket hos oss.',
    dk: 'Få at kunne se vores indhold skal du have et abonnement hos os.',
    no: 'Du må tegne et abonnement for å se på C More'
  },
  getSubscription: {
    se: 'Skaffa paket hos C More',
    dk: 'Få et abonnement hos C More',
    no: 'Bli kunde hos C More'
  },
  choosePackage: {
    se: 'Välj paket',
    dk: 'Vælg abonnement',
    no: 'Velg pakke'
  },
};

export default getTranslation(translations);
