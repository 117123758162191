import { css } from 'styled-components';
import { white, black } from '../common/styled/colors';

export default css`
  body {
    -webkit-font-smoothing: antialiased;
    color: ${black};
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  h1 {
    font-family: 'Pebble';
    color: ${black};
    display: block;
    font-weight: 400;
    font-size: 32px;
    line-height: 1.3;
  }
  p {
    /* color: ${white}; */
  }

  p,
  a {
    font-weight: 500;
  }

  h1,
  p,
  a {
    text-align: center;
  }
  a {
    color: black;
  }
`;
