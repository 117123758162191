import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { FormStyled } from '../common/styled/Forms';
import i18n from './i18n';
import globalI18n from '../globalI18n';
import { Header, AlignCenter, StyledButton, StyledGrayButton } from '../common/styled/Page';
import { withRouter } from 'react-router';
import Input from '../Input/Input';
import BankId from './BankId.js';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
    };
  }

  canBeSubmitted = () => {
    const { password } = this.state;
    return password.length >= 1;
  };

  handleSubmit = e => {
    e.preventDefault();
    if (!this.canBeSubmitted()) {
      return false;
    }
    const { password, username } = this.state;
    this.props.onSubmit(username, password);
  };

  render() {
    const { password, username } = this.state;
    const isEnabled = this.canBeSubmitted();
    const { location } = this.props;

    let userNameLabel = username || '';

    if (location.state && location.state.usernameFromLocation) {
      const { usernameFromLocation } = location.state;
      userNameLabel = usernameFromLocation;
    }

    return (
      <FormStyled noValidate onSubmit={this.handleSubmit}>
         <Header>{i18n('header')}</Header>
        <Input
          id="username"
          label={globalI18n('general.username')}
          onChange={e => this.setState({ username: e.target.value })}
          value={userNameLabel}
          autoFocus={true}
        />
        <br />
        <Input
          id="password"
          state={isEnabled ? 'VALID' : 'DEFAULT'}
          label={globalI18n('general.password')}
          onChange={e => this.setState({ password: e.target.value })}
          autoCapitalize="off"
          data-test="password"
          autoComplete="current-password"
          value={password}
          showPasswordIcon="true"
        />
        <React.Fragment>
          <Link
            style={{ textAlign: 'right', display: 'block', marginLeft: 'auto', marginBottom: 16 ,marginTop: 8,fontSize: 14 }}
            to={{ pathname: '/forgot-password', state: { usernameFromLocation: this.state.username } }}
          >
            {' '} UPDATE URL
            {i18n('forgotPassword')}
          </Link>
        </React.Fragment>
        {this.props.children}
        <AlignCenter>
          <StyledButton disabled={!isEnabled} variant="primary" isLoading={this.props.loading} size="wide">
            {i18n('submitButton')}
          </StyledButton>
          <StyledGrayButton variant="primary" size="wide" Icon={BankId} href={"/"}>
            {i18n('loginOptions')}
          </StyledGrayButton>
        </AlignCenter>
      </FormStyled>
    );
  }
}

export default withRouter(LoginForm);
