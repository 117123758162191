import config from 'config';

// curl - X GET "https://iptvapi.pilot.telia.net/logingateway/rest/v1/ciam/device/verifyUserCode?userCode=MVWC&whiteLabelBrand=TELIA" - H "accept: application/json" - H "X-Country: SE" - H "Content-Type: application/json"
const LoginGatewayActivationService = {
  // Nu: kolla mot activation-bravo om koden finns eller inte
  // Sen: kolla mot login-gateway om koden finns eller inte
  validate: async (activationCode, country) => {
    var url = `https://${config.loginGatewayBaseUrl}/rest/v1/ciam/device/verifyUserCode?userCode=${activationCode}&whiteLabelBrand=TELIA`;
    var response = await fetch(url, {
      method: 'GET',
      headers: {
        'X-Country': country,
        'Content-Type': 'application/json'
      }
    });
    console.log('validate', response);
    return response.status === 200;
  },

  loginDevice: async (username, password, activationCode, deviceId, country) => {
    var url = `https://${config.loginGatewayBaseUrl}/rest/v1/ciam/device/login`;
    var body = {
      credentials: {
        deviceId: deviceId,
        deviceType: 'WEB',
        password: password,
        username: username,
        whiteLabelBrand: 'TELIA'
      },
      userCode: activationCode
    };
    var response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-Country': country,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
console.log('loginDevice', response);
    return response;
  },

  //curl -X POST "https://iptvapi.pilot.telia.net/logingateway/rest/v1/login" -H  "accept: application/json" -H  "X-Country: SE" -H  "Content-Type: application/json" -d "{  \"deviceId\": \"123\",  \"username\": \"emil.vikstrom@tv4.se\",  \"password\": \"******\",  \"deviceType\": \"WEB\",  \"whiteLabelBrand\": \"TELIA\"}"

  loginWeb: async (username, password, deviceId, country) => {
    var url = `https://${config.loginGatewayBaseUrl}/rest/v1/login`;
    var body = {
      deviceId: 'companion_login',
      deviceType: 'WEB',
      username: username,
      password: password,
      whiteLabelBrand: 'TELIA'
    }

    var response = await fetch(url, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'X-Country': country,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });


    return response;
  },

  // curl -X GET "https://iptvapi.pilot.telia.net/engagementgateway/rest/secure/v2/engagementinfo"
  // -H  "accept: application/json"
  // -H  "Authorization: Bearer xxxx"
  engagementInfo: async (accessToken) => {
    var url = `https://${config.engagementGatewayBaseUrl}/rest/secure/v2/engagementinfo`;
    var response = await fetch(url, {
      method: 'GET',
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });


    return response;
  },
};

export default LoginGatewayActivationService;
